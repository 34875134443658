const menuItems = [
  {
    display: 'Home',
    path: '/',
  },
  {
    display: 'Movies',
    path: '/movie',
  },
  {
    display: 'Series',
    path: '/tv',
  },
];

export default menuItems;
